import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Landing from './pages/Landing'
import CustomerLogin from './pages/CustomerLogin';
import Parceltracking from './pages/Parceltracking';
import AboutUs from './pages/AboutUs';
import CreateAdmin from './pages/CreateAdmin';
import Paymentlink from './pages/Paymentlink';

//user routes
import UserRoutes from './pages/privateroutes/UserRoutes'
import PackageInfo from './pages/user/PackageInfo';
import PaymentInfo from './pages/user/PaymentInfo';
import CryptoPayment from './pages/user/CryptoPayment';


//admin routes
import AdminRoutes from './pages/privateroutes/AdminRoutes'
import AdminDashboard from './pages/admin/AdminDashboard';
import SendEmail from './pages/admin/SendEmail';

function App() {
  return (
    <Router>
      <Routes>
          <Route path="*" element={<Landing />}/>
          <Route path='/' element={<Landing />}/>
          <Route path='/createadmin' element={< CreateAdmin/>}/>
          <Route path='/customerlogin' element={<CustomerLogin />}/>
          <Route path='/parceltracking' element={<Parceltracking />}/>
          <Route path='/Paymentlink' element={<Paymentlink />}/>
          <Route path='/aboutus' element={<AboutUs />}/>

          <Route element={<UserRoutes />}>
            <Route path='/user/packageinfo' element = {< PackageInfo />} />
            <Route path='/user/PaymentInfo' element = {<PaymentInfo />} />
            <Route path='/user/PaymentInfo/cryptopayment' element = {<CryptoPayment />} />
          </Route>

          <Route element={<AdminRoutes />}>
            <Route path='/admin/dashboard' element = {< AdminDashboard />} />
            <Route path='/admin/sendemail' element = {< SendEmail />} />
          </Route>

      </Routes>
    </Router>
  );
}

export default App;
