import React, { useState, useEffect } from 'react'
import {Link, useNavigate} from "react-router-dom"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../images/Logo.svg'
import {HiMenuAlt3} from "react-icons/hi"
import spinner from "../images/spinner.svg"

const Parceltracking = () => {

    const navigate = useNavigate();

    //screen width-----------------------------------------------------
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [activeNav, updateactiveNav] = useState(null)

    // Update screen width when the window is resized
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    //tracking form submit-------------------------------
    const [trackValues, setTrackValues] = useState({
        id: "",
    });
    const [isLoadingBtn, updateisLoadingBtn] = useState(null)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const TrackingSubmit = async (e) =>{
        e.preventDefault();
        updateisLoadingBtn(true)
        setIsButtonDisabled(true)

        try {
            updateActiveToast(false)
            const sendReq = await axios.post('/api/user/trackpackage', {...trackValues}) 
            const reqdata = sendReq.data;

            if(reqdata.errMsg){
                toast.error(reqdata.errMsg, {
                    position:"top-center",
                    autoClose: 5000,
                    hideProgressBar:false,
                    newestOnTop:true,
                    closeOnClick:true,
                    rtl:false,
                    pauseOnFocusLoss:false,
                    draggable:false,
                    pauseOnHover: false,   
                });
                window.scroll(0, 0)
                updateisLoadingBtn(false)
                setIsButtonDisabled(false)
            }else{
                updateActiveToast(false)
                updateisLoadingBtn(false)
                setIsButtonDisabled(false)
                sessionStorage.setItem(`userInfo`, JSON.stringify(reqdata.token)) 
                navigate('/user/packageinfo')
            }
        } catch (error) {
            console.log(error)
            updateisLoadingBtn(false)
            setIsButtonDisabled(false)
        }
    }



    //subscribe to newsletter------------------------------------------------
    const [subForm, upsubForm] = useState({
        newsletter : '',
    })
    const [aciveToast, updateActiveToast] = useState(true)

    const subNewsLetter = async () =>{
        try {
            const sendReq = await axios.post('/api/newsletter', {...subForm}) 
            const reqdata = sendReq.data;

            if(reqdata.successMsg){
                updateActiveToast(true)
                toast.success(reqdata.successMsg, {
                    position:"top-center",
                    autoClose: 5000,
                    hideProgressBar:false,
                    newestOnTop:true,
                    closeOnClick:true,
                    rtl:false,
                    pauseOnFocusLoss:false,
                    draggable:false,
                    pauseOnHover: false,   
                });
                upsubForm({newsletter : ''})
            }else{
                updateActiveToast(true)
                toast.error(reqdata.errMsg, {
                    position:"top-center",
                    autoClose: 5000,
                    hideProgressBar:false,
                    newestOnTop:true,
                    closeOnClick:true,
                    rtl:false,
                    pauseOnFocusLoss:false,
                    draggable:false,
                    pauseOnHover: false,   
                });   
                upsubForm({newsletter : ''}) 
            }
        } catch (error) {
            console.log(error)
        }
    }

  return (
    <div className={` overflow-x-hidden ${activeNav ? 'fixed' : 'absolute'} w-full min-h-full text-black bg-[#FFFFFF]`}>
        
        <nav className='relative py-[2rem] h-[100px] w-full flex justify-between items-center bg-white md:px-[1rem]'>
            <img src = {logo} alt='logo' className='z-40  pl-2 max-w-[200px] max-h-full'/>
            {screenWidth > 891 && <div className='relative flex items-center space-x-8 pr-2'>
                <Link to = "/" className='text-black font-[550] transition-all duration-300 ease-in hover:text-UserOrageLight'>Home</Link>
                <Link to = "/aboutus" className='text-black font-[550] transition-all duration-300 ease-in hover:text-UserOrageLight'>About Us</Link>
                <Link to = "/parceltracking" className='text-black font-[550] transition-all duration-300 ease-in hover:text-UserOrageLight'>Parcel Tracking</Link>
                <Link to = "/customerlogin" className='text-black font-[550] transition-all duration-300 ease-in hover:text-UserOrageLight'>Customer Login </Link>
            </div>}

            {!activeNav && <button onClick={()=>updateactiveNav(true)} className='z-40 flex pr-2 md:hidden'>
                <HiMenuAlt3 className = 'text-[25px] text-[#222222]'/>
            </button>}

            {activeNav && <button onClick={()=>updateactiveNav(false)} className='z-40 flex pr-2 md:hidden'>
                <p className = 'z-40 text-[29px] font-[400] text-white'>x</p>
            </button>}
        </nav>


        <div className={`absolute z-30 h-screen w-screen ${activeNav ? 'top-[0]' : 'top-[-200vh]' } left-0 transition-top duration-500 ease-linear`}>
            <div className='relative h-[100%] w-full flex flex-col justify-start items-end space-y-[6rem] bg-black py-[120px] px-[1rem]'>
                <Link to="/" className='text-white'>Home</Link>
                <Link to="/aboutus" className='text-white'>About Us</Link>
                <Link to="/parceltracking" className='text-white'>Parcel Tracking</Link>
                <Link to="/customerlogin" className='text-white'>Customer Login</Link> 
            </div>
        </div>

        <div className='relative w-full min-h-[200px] px-[1rem] flex flex-col justify-start items-start pt-[6rem]'>

            <div className='relative'>
              <h1 className='text-black text-[22px] font-[550] pb-2'>Swiftlinecargos Tracking Portal</h1>
              <p className='text-UserOrageLight pb-[3rem]'>Track Your Package.</p>
            </div>

            <div className='relative w-full flex justify-start items-center'>
                {!aciveToast &&  <ToastContainer  className="relative max-w-[250px] pb-4"/>}
            </div>

            <form action="" className='w-full h-full flex flex-col justify-center items-start space-y-6' onSubmit={(e) => TrackingSubmit(e)}>
                <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                    <label htmlFor="id" className='text-[15px] text-left text-black font-[450]'>Tracking ID</label>
                    <input type="text" name='id' className='px-2 border-[#d9d9d9] border-[1px] rounded-[2px] w-full h-[45px] text-black text-[16px] sm:w-[450px] focus:outline-none focus:border-UserOrageLight ' onChange={(e)=> setTrackValues({...trackValues, [e.target.name]:e.target.value})} />
                </div>

                <button type='submit' disabled = {isButtonDisabled} className='bg-UserOrageLight transition-colors duration-150 ease-linear text-white border-white rounded-md flex justify-center items-center text-center w-full h-[50px] hover:bg-[#f87e2c] sm:w-[450px]'>
                    {isLoadingBtn ? <img src={spinner} className='w-[50px] h-[50px]' alt='loading'  /> : <p>Track</p>} 
                </button>
            </form>

        </div>       

        <footer className='bg-[#ebf5ff] relative mt-[5rem] w-full min-h-[200px] py-6 px-3 pb-[4rem] md:px-8 xl:fixed xl:bottom-0 xl:left-0'>
            <div className='relative w-full min-h-full flex flex-col justify-start items-start space-y-6 md:flex-row md:justify-evenly md:space-x-4 md:items-start md:space-y-0'>
                <div className='relative w-full flex flex-col space-y-4 max-w-[400px]'>
                    <img src = {logo} alt='logo' className='max-w-[150px] max-h-full'/>
                    <p className='max-w-[410px]'>Swiftlinecargos is one of the fastest growing logistics service providers delivering goods 7 days a week across the UK, Europe, America and Asia.</p>
                </div>

                <div className='relative flex flex-col space-y-4'>
                    <h4 className='text-[22px] font-[550]'>Useful Links</h4>

                    <div className='flex flex-col space-y-2'>
                        <Link to = "/">Home</Link>
                        <Link to = "/aboutus">About Us</Link>
                        <Link to = "/parceltracking">Parcel Tracking</Link>
                        <Link to = "/customerlogin">Customer Login</Link>
                    </div>
                </div>

                <div className='relative flex flex-col space-y-4'>
                    <h4 className='text-[22px] font-[550]'>Subscribe Newsletter</h4>

                    <p className='max-w-[300px]'>By subscribing to our mailing list you will always be update with the latest news from us.</p>

                    <div className='relative w-full flex justify-start items-start'>
                        {aciveToast && <ToastContainer  className="relative pb-4 max-w-[250px]"/>}
                    </div>

                    <div className='flex space-x-2'>  
                      <input type="text" name='newsletter' value = {subForm.newsletter} onChange={(e)=> upsubForm({...subForm, [e.target.name]:e.target.value})} placeholder='Enter Email Address' className='bg-mainBackColor px-2 border-[#d9d9d9] border-[1px] rounded-[5px] w-full h-[45px] text-black text-[16px] focus:outline-none focus:border-UserOrageLight' />
                      <button onClick={()=> subNewsLetter()} className='text-[14px] max-w-[130px] px-2 h-[45px] border border-UserOrageLight rounded-sm text-white bg-UserOrageLight'>Subscribe</button>
                    </div>
                </div>
            </div>

            <div className='relative border-t border-t-[#dbdee2] w-full h-[2px] pt-[4rem] mt-[3rem] flex flex-col justify-center items-center space-y-4 md:flex-row md:space-x-6 md:space-y-0'>
                <p className='text-[14px] text-[#303030]'>© 2023 All Rights Reserved by Swiftlinecargos</p>
                <p className='text-[14px] text-[#303030]'>Terms & Conditions</p>
            </div>

        </footer>

    </div>

  )
}

export default Parceltracking