import React from 'react';
import { BiLogOut } from "react-icons/bi";
import {Link, useNavigate} from "react-router-dom"
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { ImCancelCircle } from "react-icons/im"
import axios from 'axios';
import { useGlobalContext } from '../../context';
import ReactDataTable from '../components/ReactDataTable';

const AdminDashboard = () => {

    const navigate = useNavigate();

    // Get the token from sessionStorage
    const adminToken = sessionStorage.getItem('adminInfo');

    //get all admin data for tables
    const { allAminData } = useGlobalContext();
    const [trackingData, updateTrackingData] = useState([])
    const [userPayment, updatePaymentData] = useState([])
    const [userData, updateUserData] = useState([])

    useEffect(() => {

        if (allAminData) {
            updateTrackingData(allAminData[0])
            updatePaymentData(allAminData[1])
            updateUserData(allAminData[2])
        }
    }, [allAminData])



    //delete package on user click------------------------------------------
    const deletePackage = async (id) => {
        try {
            const response = await axios.delete('/api/admin/deletepackage',
                {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },

                    data: {
                        id
                    },
                });

            const responseData = response.data.successMsg;

            toast.success(responseData, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                newestOnTop: true,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: false,
                draggable: false,
                pauseOnHover: false,
            })

            setTimeout(() => {
                window.location.reload();
            }, 5000);

        } catch (error) {
            console.log(error)
        }
    }

    //data table for tracking-----------------------------------------
    const columns = [
        {
            name: 'Send Date',
            selector: (row) => row.firstlocationdate,
            cell: (row) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {new Date(row.firstlocationdate).toLocaleDateString('en-GB')}
                </div>
            )
        },
        {
            name: 'Arrive Date',
            selector: (row) => row.destinationDate,
            cell: (row) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {row.destinationDate}
                </div>
            )
        },
        {
            name: 'Email',
            selector: row => row.email,
            cell: (row) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {row.email}
                </div>
            )
        },
        {
            name: 'Tracking Id',
            selector: row => row.trackingid,
            cell: (row) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {row.trackingid}
                </div>
            )
        },
        {
            name: 'Sender',
            selector: row => row.sendername,
            cell: (row) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {row.sendername}
                </div>
            )
        },
        {
            name: 'Receiver',
            selector: row => row.receivername,
            cell: (row) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {row.receivername}
                </div>
            )
        },
        {
            name: 'Address Send',
            selector: row => row.origin,
            cell: (row) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {row.origin}
                </div>
            )
        },
        {
            name: 'Address Receive',
            selector: row => row.destination,
            cell: (row) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {row.destination}
                </div>
            )
        },
        {
            name: 'Status',
            selector: row => row.packagestatus,
            cell: (row) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {row.packagestatus}
                </div>
            )
        },
        {
            name: 'Action',
            cell: (row) => (
                <div style={{ whiteSpace: 'normal', display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
                    <button onClick={() => deletePackage(row.trackingid)} className="bg-red-700 text-white flex justify-center items-center min-w-[60px] h-[25px] border border-white rounded-md p-2">Delete</button>
                </div>
            )
        },
    ];


    //data table for tracking-----------------------------------------
    const userpayment = [
        {
            name: 'Tracking Id',
            selector: row => row.trackingid,
            cell: (row) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {row.trackingid}
                </div>
            )
        },
        {
            name: 'Status',
            selector: row => row.feestatus,
            cell: (row) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {row.feestatus}
                </div>
            )
        },
        {
            name: 'Pending Payment',
            selector: row => row.pendingpaymnet,
            cell: (row) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {row.pendingpaymnet}
                </div>
            )
        },
    ];

    const userdata = [
        {
            name: 'Email',
            selector: row => row.email,
            cell: (row) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {row.email}
                </div>
            )
        },
        {
            name: 'User',
            selector: row => row.isUser,
            cell: (row) => (
                <div style={{ whiteSpace: 'normal' }}>
                     {row.isUser ? 'Yes' : 'No'}
                </div>
            )
        },
    ];   

    //create package form and submit------------------------
    const [activeForm, updateactiveForm] = useState(null)
    const [Form, udpateForm] = useState({
        email: "",
        trackingid: "",
        senderName: "",
        receiverName: "",
        pendingamount: "",
        secondLocation: "",
        secondLocDate: "",
        thirdLocation: "",
        thirdLocationDate: "",
        addresssend: "",
        addressreceive: "",
        destinationDate: "",
        status: "",
        dimension: "",
        weight: "",
    })

    const packageSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/admin/createpackage', { ...Form },
                {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                })
            updateactiveForm(false)
            const responseData = response.data;

            if (responseData.errMsg) {
                toast.error(responseData.errMsg, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    newestOnTop: true,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: false,
                    draggable: false,
                    pauseOnHover: false,
                })
            }
            else {
                toast.success(responseData.successMsg, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    newestOnTop: true,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: false,
                    draggable: false,
                    pauseOnHover: false,
                })
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            }

        } catch (error) {
            console.log(error)
        }
    }

    //update middle destinations-------------------------------------------------
    const [middleDestForm, updateMiddleDestForm] = useState(null)
    const [sendMiddleForm, updateSendMiddleForm] = useState({
        trackingid: "",
        secondLocation: '',
        secondLocDate: "",
        thirdLocation: "",
        thirdLocationDate: "",
    })

    const MiddleDestSend = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/admin/updatemiddlepackage', { ...sendMiddleForm },
                {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                })
            updateMiddleDestForm(false)
            const responseData = response.data;

            if (responseData.errMsg) {
                toast.error(responseData.errMsg, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    newestOnTop: true,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: false,
                    draggable: false,
                    pauseOnHover: false,
                })
            }
            else {
                toast.success(responseData.successMsg, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    newestOnTop: true,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: false,
                    draggable: false,
                    pauseOnHover: false,
                })
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            }

        } catch (error) {
            console.log(error)
        }
    }


    //update package form and submit----------------------------------------------------
    const [upPackage, updateUpForm] = useState(null)
    const [UpForm, updateform] = useState({
        id: "",
        field: "",
        update: ""
    })

    const packageupdate = async (e) => {

        e.preventDefault();
        try {
            const response = await axios.post('/api/admin/updatepackage', { ...UpForm },
                {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                })
            updateUpForm(false)
            const responseData = response.data;

            if (responseData.errMsg) {
                toast.error(responseData.errMsg, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    newestOnTop: true,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: false,
                    draggable: false,
                    pauseOnHover: false,
                })
            }
            else {
                toast.success(responseData.successMsg, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    newestOnTop: true,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: false,
                    draggable: false,
                    pauseOnHover: false,
                })
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            }

        } catch (error) {
            console.log(error)
        }

    }

    //payment info update-----------------------------------------
    const [ActivePaymentForm, updateActivePaymentForm] = useState(null)
    const [payFormSub, updatePayFormSub] = useState({
        id: "",
        field: "",
        update: ""
    })

    const PaymentUpdate = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('/api/admin/updatepayment', { ...payFormSub },
                {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                })
            updateActivePaymentForm(false)
            const responseData = response.data;

            if (responseData.errMsg) {
                toast.error(responseData.errMsg, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    newestOnTop: true,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: false,
                    draggable: false,
                    pauseOnHover: false,
                })
            }
            else {
                toast.success(responseData.successMsg, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    newestOnTop: true,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: false,
                    draggable: false,
                    pauseOnHover: false,
                })
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            }

        } catch (error) {
            console.log(error)
        }

    }

    return (
        <div className='absolute overflow-x-hidden w-full min-h-full text-white bg-[#efefef] px-3 py-2 md:px-8'>

            <nav className='relative w-full min-h-[50px] flex justify-start items-center'>
                <button className='flex justify-center items-center w-[35px] h-[35px] bg-[#bfbfbf] rounded-[35px]'>
                    <BiLogOut onClick={()=> {window.sessionStorage.clear(); navigate('/customerlogin')}} className='w-[20px] h-[20px] text-black' />
                </button>
            </nav>

            {activeForm && <div className='absolute w-full py-[5rem] min-h-[100%] top-0 left-0 flex justify-center items-center bg-[#111111c9] z-50'>
                <div className='relative min-h-[400px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-[#d6d6d6] p-4 border border-[#d6d6d6] rounded-md sm:w-[400px] ssm:w-[80%]'>
                    <div className='relative w-full flex justify-end items-center'>
                        <button className="relative " onClick={() => { updateactiveForm(false) }}  >
                            <ImCancelCircle className='cursor-pointer text-[22px] text-black transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
                        </button>
                    </div>

                    <form action="" className='w-full' onSubmit={(e) => packageSubmit(e)}>
                        <div className='relative w-[100%] min-h-[54px] flex flex-col justify-start items-center space-y-3'>
                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="email" className='text-[15px] text-left text-[#000000d9] font-[450]'>Email</label>
                                <input type="email" name='email' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => udpateForm({ ...Form, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="amount" className='text-[15px] text-left text-[#000000d9] font-[450]'>Tracking ID</label>
                                <input type="text" name='trackingid' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => udpateForm({ ...Form, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="type" className='text-[15px] text-left text-[#000000d9] font-[450]'>Sender Name</label>
                                <input type="text" name='senderName' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => udpateForm({ ...Form, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="type" className='text-[15px] text-left text-[#000000d9] font-[450]'>Receiver Name</label>
                                <input type="text" name='receiverName' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => udpateForm({ ...Form, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="destinationDate" className='text-[15px] text-left text-[#000000d9] font-[450]'>Destination Date</label>
                                <input type="text" name='destinationDate' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => udpateForm({ ...Form, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="type" className='text-[15px] text-left text-[#000000d9] font-[450]'>Pending amount</label>
                                <input type="text" name='pendingamount' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => udpateForm({ ...Form, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="type" className='text-[15px] text-left text-[#000000d9] font-[450]'>Sender address</label>
                                <input type="text" name='addresssend' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => udpateForm({ ...Form, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="type" className='text-[15px] text-left text-[#000000d9] font-[450]'>Receiver address</label>
                                <input type="text" name='addressreceive' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => udpateForm({ ...Form, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="type" className='text-[15px] text-left text-[#000000d9] font-[450]'>Status</label>
                                <input type="text" name='status' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => udpateForm({ ...Form, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="type" className='text-[15px] text-left text-[#000000d9] font-[450]'>Dimension</label>
                                <input type="text" name='dimension' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => udpateForm({ ...Form, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="type" className='text-[15px] text-left text-[#000000d9] font-[450]'>Weight</label>
                                <input type="text" name='weight' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => udpateForm({ ...Form, [e.target.name]: e.target.value })} />
                            </div>
                        </div>

                        <div className='flex justify-center items-center pt-[1rem]'>
                            <button type='submit' className='min-w-[120px] min-h-[25px] p-2 font-[550] flex justify-center items-center bg-white text-black border border-white rounded-md'> Create</button>
                        </div>
                    </form>

                </div>
            </div>}

            {middleDestForm && <div className='absolute w-full py-[5rem] min-h-[100%] top-0 left-0 flex justify-center items-center bg-[#111111c9] z-50'>
                <div className='relative min-h-[400px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-[#d6d6d6] p-4 border border-[#d6d6d6] rounded-md sm:w-[400px] ssm:w-[80%]'>
                    <div className='relative w-full flex justify-end items-center'>
                        <button className="relative " onClick={() => { updateMiddleDestForm(false) }}  >
                            <ImCancelCircle className='cursor-pointer text-[22px] text-black transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
                        </button>
                    </div>

                    <form action="" className='w-full' onSubmit={(e) => MiddleDestSend(e)}>
                        <div className='relative w-[100%] min-h-[54px] flex flex-col justify-start items-center space-y-3'>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="trackingid" className='text-[15px] text-left text-[#000000d9] font-[450]'>Tracking Number</label>
                                <input type="text" name='trackingid' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => updateSendMiddleForm({ ...sendMiddleForm, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="secondLocation" className='text-[15px] text-left text-[#000000d9] font-[450]'>Second Location</label>
                                <input type="text" name='secondLocation' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => updateSendMiddleForm({ ...sendMiddleForm, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="secondLocDate" className='text-[15px] text-left text-[#000000d9] font-[450]'>Second Location Date</label>
                                <input type="text" name='secondLocDate' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => updateSendMiddleForm({ ...sendMiddleForm, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="thirdLocation" className='text-[15px] text-left text-[#000000d9] font-[450]'>Third Location</label>
                                <input type="text" name='thirdLocation' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => updateSendMiddleForm({ ...sendMiddleForm, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="thirdLocationDate" className='text-[15px] text-left text-[#000000d9] font-[450]'>Third Location Date</label>
                                <input type="text" name='thirdLocationDate' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => updateSendMiddleForm({ ...sendMiddleForm, [e.target.name]: e.target.value })} />
                            </div>
                        </div>

                        <div className='flex justify-center items-center pt-[1rem]'>
                            <button type='submit' className='min-w-[120px] min-h-[25px] p-2 font-[550] flex justify-center items-center bg-white text-black border border-white rounded-md'> Create</button>
                        </div>
                    </form>
                </div>
            </div>
            }

            {upPackage && <div className='absolute w-full py-[5rem] min-h-[100%] top-0 left-0 flex justify-center items-center bg-[#111111c9] z-50'>
                <div className='relative min-h-[400px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-[#d6d6d6] p-4 border border-[#d6d6d6] rounded-md sm:w-[400px] ssm:w-[80%]'>

                    <div className='relative w-full flex justify-end items-center'>
                        <button className="relative " onClick={() => { updateUpForm(false) }}  >
                            <ImCancelCircle className='cursor-pointer text-[22px] text-black transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
                        </button>
                    </div>

                    <form action="" className='w-full' onSubmit={(e) => packageupdate(e)}>
                        <div className='relative w-[100%] min-h-[54px] flex flex-col justify-start items-center space-y-3'>
                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="id" className='text-[15px] text-left text-[#000000d9] font-[450]'>Find</label>
                                <input type="text" name='id' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => updateform({ ...UpForm, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="field" className='text-[15px] text-left text-[#000000d9] font-[450]'>Info</label>
                                <input type="text" name='field' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => updateform({ ...UpForm, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="update" className='text-[15px] text-left text-[#000000d9] font-[450]'>Update</label>
                                <input type="text" name='update' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => updateform({ ...UpForm, [e.target.name]: e.target.value })} />
                            </div>
                        </div>

                        <div className='flex justify-center items-center pt-[1rem]'>
                            <button type='submit' className='min-w-[120px] min-h-[25px] p-2 font-[550] flex justify-center items-center bg-white text-black border border-white rounded-md'> Update</button>
                        </div>
                    </form>

                </div>
            </div>}

            {ActivePaymentForm && <div className='absolute w-full py-[5rem] min-h-[100%] top-0 left-0 flex justify-center items-center bg-[#111111c9] z-50'>
                <div className='relative min-h-[400px] w-[90%] flex flex-col justify-start items-center space-y-4 bg-[#d6d6d6] p-4 border border-[#d6d6d6] rounded-md sm:w-[400px] ssm:w-[80%]'>

                    <div className='relative w-full flex justify-end items-center'>
                        <button className="relative " onClick={() => { updateActivePaymentForm(false) }}  >
                            <ImCancelCircle className='cursor-pointer text-[22px] text-black transition-all ease-in duration-300 hover:scale-[1.1] md:text-[25px]' />
                        </button>
                    </div>

                    <form action="" className='w-full' onSubmit={(e) => PaymentUpdate(e)}>
                        <div className='relative w-[100%] min-h-[54px] flex flex-col justify-start items-center space-y-3'>
                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="id" className='text-[15px] text-left text-[#000000d9] font-[450]'>Find</label>
                                <input type="text" name='id' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => updatePayFormSub({ ...payFormSub, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="field" className='text-[15px] text-left text-[#000000d9] font-[450]'>Info</label>
                                <input type="text" name='field' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => updatePayFormSub({ ...payFormSub, [e.target.name]: e.target.value })} />
                            </div>

                            <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                                <label htmlFor="update" className='text-[15px] text-left text-[#000000d9] font-[450]'>Update</label>
                                <input type="text" name='update' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => updatePayFormSub({ ...payFormSub, [e.target.name]: e.target.value })} />
                            </div>
                        </div>

                        <div className='flex justify-center items-center pt-[1rem]'>
                            <button type='submit' className='min-w-[120px] min-h-[25px] p-2 font-[550] flex justify-center items-center bg-white text-black border border-white rounded-md'> Update</button>
                        </div>
                    </form>

                </div>
            </div>}

            <div className='relative max-w-full flex justify-center items-center px-4'>
                <ToastContainer className="relative pb-4 max-w-[300px]" />
            </div>

            <div className='relative w-full flex flex-col justify-center items-center space-y-4 pt-[4rem]'>
                <button onClick={() => { updateactiveForm(true); window.scroll(0, 0) }} className='min-w-[120px] min-h-[25px] p-2 flex justify-center items-center bg-black text-white border border-black rounded-md'>Create Package</button>
                <button onClick={() => { updateMiddleDestForm(true); window.scroll(0, 0) }} className='min-w-[120px] min-h-[25px] p-2 flex justify-center items-center bg-black text-white border border-black rounded-md'>Update Destinations</button>
                <button onClick={() => { updateUpForm(true); window.scroll(0, 0) }} className='min-w-[120px] min-h-[25px] p-2 flex justify-center items-center bg-black text-white border border-black rounded-md'>Update Package</button>
                <button onClick={() => { updateActivePaymentForm(true); window.scroll(0, 0) }} className='min-w-[120px] min-h-[25px] p-2 flex justify-center items-center bg-black text-white border border-black rounded-md'>Update Payment</button>
                <Link to = "/admin/sendemail" className='min-w-[120px] min-h-[25px] p-2 flex justify-center items-center bg-black text-white border border-black rounded-md'>Send Email</Link>
            </div>

            {/* tracking start */}
            <div className='relative max-w-full flex justify-center items-center pt-[5rem] '>
                <ReactDataTable columns={columns} data={trackingData} />
            </div>

            {/* user payment start */}
            <div className='relative max-w-full flex justify-center items-center pt-[5rem] '>
                <ReactDataTable columns={userpayment} data={userPayment} />
            </div>
            
            {/* user payment start */}
            <div className='relative max-w-full flex justify-center items-center pt-[5rem] '>
                <ReactDataTable columns={userdata} data={userData} />
            </div>

        </div>
    )
}

export default AdminDashboard