import React, { useState, useEffect } from 'react'
import {Link, useNavigate} from "react-router-dom"
import logo from '../../images/Logo.svg'
import { useGlobalContext } from '../../context';

const PackageInfo = () => {

    const navigate = useNavigate();

    const{trackingInfoUser, userPayInfo} = useGlobalContext();
    const [trackingData, updatetrackingData] = useState({})
    const [paymentData, updatePaymentData] = useState({})

    useEffect(()=>{
        if(trackingInfoUser && userPayInfo){
            updatetrackingData(trackingInfoUser)
            updatePaymentData(userPayInfo)
        }
    }, [trackingInfoUser, userPayInfo])

  return (
    <div className='absolute overflow-hidden w-full min-h-full text-white bg-[#414141] px-3 pt-2 pb-[5rem] md:px-8'>

        <nav className='relative w-full min-h-[50px] flex justify-between items-center'>
            <button onClick={()=>{window.sessionStorage.clear(); navigate('/customerlogin')}}>
                <img src = {logo} alt='logo' className='max-w-[100px] h-[100px]'/>
            </button>

            {paymentData.feestatus === 'Pending' ? <Link to = '/user/PaymentInfo' className='relative flex justify-center items-center w-[100px] h-[30px] border border-UserOrageLight rounded-[30px]'>
                <p className='text-[13px] text-UserOrageLight'>Payment</p>
            </Link> 
            :
            <div className='relative flex justify-center items-center w-[100px] h-[30px] border bg-UserOrageLight border-UserOrageLight rounded-[30px]'>
                <p className='text-[13px] text-white'>Transit</p>
            </div>}
        </nav>

        <div className='relative w-full flex flex-col justify-center items-center space-y-8 pt-[6rem]'>
            
            <div className='relative flex flex-col space-y-2 w-full min-h-[230px] px-6 py-5 bg-Userbglight border border-Userbglight rounded-md sm:w-[500px]'>

                <div className='w-full flex justify-between items-center'>
                    <p className='tracking-wide text-UserlightWhite text-[16px] md:text-[18px]'>Current Shipping</p>

                    <div className='flex justify-center items-center max-w-[100px] h-[30px] px-3 bg-UserOrageLight border-UserOrageLight rounded-[30px]'>
                        <p className='text-[14px] tracking-wide'>{trackingData.packagestatus}</p>
                    </div>
                </div>

                <h4 className='text-[15px] text-[#6d6d6d] pb-[2rem]'>ID {trackingData.trackingid}</h4>


                <div className='relative min-h-[400px] w-full flex flex-col justify-between'>
                    <div className="absolute w-[3px] h-full bg-gray-200 dark:bg-gray-700"></div>                    

                    <div className="relative pb-[4px] w-full flex-grow h-[25%] flex items-start justify-start space-x-[2rem]">
                        <div className={`z-50 absolute top-0 left-[-10px] ${trackingData.firstlocation ? 'bg-UserOrageLight' : 'bg-gray-700'}  h-6 w-6 rounded-full shadow  shrink-0`}></div>
                        <div className={`absolute top-0 left-[-32px] h-full w-[3px] ${trackingData.secondlocation ? `bg-UserOrageLight` : `bg-gray-700`}`}></div>
                       
                        <div className=' w-full'>
                            <p className=' text-[13.50px] text-UserdarkWhite'>{new Date(trackingData.firstlocationdate).toLocaleDateString('en-GB')}</p>
                            <p className=' text-[13.50px] max-w-[400px]'>{trackingData.firstlocation}</p>
                        </div>
                    </div>

                    <div className={`relative pb-[4px] w-full flex-grow h-[25%] flex items-start justify-start  space-x-[2rem]`}>
                        <div className={`z-50 absolute top-0 left-[-10px] ${trackingData.secondlocation ? `bg-UserOrageLight` : `bg-gray-700`} h-6 w-6 rounded-full shadow shrink-0`}></div>
                        <div className={`absolute top-0 left-[-32px] h-full w-[3px] ${trackingData.thirdlocation ? `bg-UserOrageLight` : `bg-gray-700`}`}></div>

                        <div className=' '>
                            <p className=' text-[13.50px] text-UserdarkWhite'>{trackingData.secondlocationdate ? trackingData.secondlocationdate : ''}</p>
                            <p className=' text-[13.50px] max-w-[400px]'>{trackingData.secondlocation}</p>
                        </div>
                    </div>

                    <div className={`relative pb-[4px] w-full flex-grow h-[25%] flex items-start justify-start space-x-[2rem]`}>
                        <div className={`z-50 absolute top-0 left-[-10px] ${trackingData.thirdlocation ? `bg-UserOrageLight` : `bg-gray-700`} h-6 w-6 rounded-full shadow shrink-0`}></div>
                        <div className={`absolute top-0 left-[-32px] h-full w-[3px] ${trackingData.destination ? `bg-UserOrageLight` : `bg-gray-700`}`}></div>

                        <div className=' '>
                            <p className='  text-[13.50px] text-UserdarkWhite'>{trackingData.thirdlocationdate ? trackingData.thirdlocationdate : ''}</p>
                            <p className=' text-[13.50px] max-w-[400px]'>{trackingData.thirdlocation}</p>
                        </div>
                    </div>

                    <div className={`relative w-full flex-grow h-[25%] flex items-end justify-start space-x-[2rem]`}>
                        <div className={`z-50 absolute bottom-0 left-[-10px] ${trackingData.destination ? `bg-UserOrageLight` : `bg-[#e1e1e1]`} h-6 w-6 rounded-full shadow shrink-0`}></div>
                        
                        <div className='flex flex-col justify-start items-start'>
                            <p className='w-full text-[13.50px] text-UserdarkWhite'> Est  {trackingData.destinationDate}</p>
                            <p className=' text-[13.50px] max-w-[400px]'>{trackingData.fourthlocation}</p>
                        </div>
                    </div>    
                </div>
              
            </div>

            <div className='relative flex flex-col items-center space-y-6 w-full min-h-[230px] px-3 py-5 bg-Userbglight border border-Userbglight rounded-md sm:w-[500px]'>
                
                <div className='relative w-full flex justify-between items-center space-x-4'>                 
                    <div className='flex flex-col space-y-1'>
                        <h4 className='text-[15px] text-[#e2e2e2]'>Tracking ID</h4>
                        <p className='text-[14px] text-UserlightWhite'>{trackingData.trackingid}</p>
                    </div>

                    <div className='w-1/2 items-end flex flex-col space-y-1'>
                        <h4 className='text-[15px] text-[#e2e2e2]'>Sender ID</h4>
                        <p className='text-[14px] text-UserlightWhite text-right'>{trackingData.sendername}</p>
                    </div>
                </div>

                <div className='relative w-full flex justify-between items-center space-x-4'>                 
                    <div className='flex flex-col space-y-1'>
                        <h4 className='text-[15px] text-[#e2e2e2]'>Receiver ID</h4>
                        <p className='text-[14px] text-UserlightWhite'>{trackingData.receivername}</p>
                    </div>

                    <div className=' w-1/2 items-end  flex flex-col space-y-1'>
                        <h4 className='text-[15px] text-[#e2e2e2]'>Status</h4>
                        <p className='text-[14px] text-UserlightWhite text-right'>{trackingData.packagestatus}</p>
                    </div>
                </div>

                <div className='relative w-full flex justify-between items-center space-x-4'>                 
                    <div className='flex flex-col space-y-1 w-1/2'>
                        <h4 className='text-[15px] text-[#e2e2e2]'>Origin</h4>
                        <p className='text-[14px] text-UserlightWhite text-left'>{trackingData.firstlocation}</p>
                    </div>

                    <div className='w-1/2 items-end flex flex-col space-y-1'>
                        <h4 className='text-[15px] text-[#e2e2e2]'>Destination</h4>
                        <p className='text-[14px] text-UserlightWhite text-right'>{trackingData.fourthlocation}</p>
                    </div>
                </div>

                <div className='relative w-full flex justify-between items-center space-x-4'>                 
                    <div className='flex flex-col space-y-1'>
                        <h4 className='text-[15px] text-[#e2e2e2]'>Dimension</h4>
                        <p className='text-[14px] text-UserlightWhite'>{trackingData.dimension}</p>
                    </div>

                    <div className='w-1/2 items-end  flex flex-col space-y-1'>
                        <h4 className='text-[15px] text-[#e2e2e2]'>Weight</h4>
                        <p className='text-[14px] text-UserlightWhite text-right'>{trackingData.weight}</p>
                    </div>
                </div>

            </div>

        </div>        
    </div>
  )
}

export default PackageInfo