import React from 'react';
import {Link} from "react-router-dom"
import { useState } from 'react';
import {  toast, ToastContainer } from 'react-toastify';
import axios from 'axios';

const SendEmail = () => {


    // Get the token from sessionStorage
    const adminToken = sessionStorage.getItem('adminInfo');

    //create email forn and submit------------------------
    const [Form, udpateForm] = useState({
        email: "",
        emailbody: "",
        header: ""
    })
 
    const emailSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/admin/sendemailtouser', { ...Form },
                {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                })

            const responseData = response.data;

            if (responseData.errMsg) {
                toast.error(responseData.errMsg, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    newestOnTop: true,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: false,
                    draggable: false,
                    pauseOnHover: false,
                })
            }
            else {
                toast.success(responseData.successMsg, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    newestOnTop: true,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: false,
                    draggable: false,
                    pauseOnHover: false,
                })

                udpateForm({
                    email: "",
                    emailbody: "",
                    header: ""
                })

                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            }

        } catch (error) {
            console.log(error)
        }
    }

  return (
    <div className='absolute overflow-x-hidden w-full min-h-full flex justify-center items-center text-white bg-[#efefef] px-3 py-2 md:px-8'>
               
        <ToastContainer  />
        
        <div className='relative min-h-[400px] w-[95%] flex flex-col justify-start items-center space-y-4 bg-[#d6d6d6] p-4 border border-[#d6d6d6] rounded-md sm:w-[400px] ssm:w-[95%]'>
        

            <Link to = '/admin/dashboard' className='relative flex justify-center items-center w-[100px] h-[30px] border border-UserOrageLight rounded-[30px]'>
                <p className='text-[13px] text-UserOrageLight'>back</p>
            </Link> 
            
            <form action="" className='w-full pt-[2rem]' onSubmit={(e) => emailSubmit(e)}>
                <div className='relative w-[100%] min-h-[54px] flex flex-col justify-start items-center space-y-3'>
                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="email" className='text-[15px] text-left text-[#000000d9] font-[450]'>Email</label>
                        <input type="email" name='email' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => udpateForm({ ...Form, [e.target.name]: e.target.value })} />
                    </div>

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="header" className='text-[15px] text-left text-[#000000d9] font-[450]'>Email header</label>
                        <input type="text" name='header' className='bg-black px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full h-[45px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => udpateForm({ ...Form, [e.target.name]: e.target.value })} />
                    </div>

                    <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                        <label htmlFor="emailbody" className='text-[15px] text-left text-[#000000d9] font-[450]'>Email body</label>
                        <textarea type="text" name='emailbody' className='bg-black resize-none px-2 border-[#d9d9d9] border-[1px] rounded-[6px] w-full min-h-[145px] text-white text-[16px] focus:outline-[0.50px] focus:outline-[#d4b657] focus:transition-all hover:border-[#c3c3c3]' onChange={(e) => udpateForm({ ...Form, [e.target.name]: e.target.value })} />
                    </div>
                </div>

                <div className='flex justify-center items-center pt-[1rem]'>
                    <button type='submit' className='min-w-[120px] min-h-[25px] p-2 font-[550] flex justify-center items-center bg-white text-black border border-white rounded-md'> Send </button>
                </div>
            </form>

        </div>
    </div>
  )
}

export default SendEmail