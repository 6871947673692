import React, { useState, useEffect } from 'react'
import {Link, useNavigate} from "react-router-dom"
import logo from '../images/Logo.svg'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import spinner from "../images/spinner.svg";
import {HiMenuAlt3} from "react-icons/hi"

const CustomerLogin = () => {

    const navigate = useNavigate();

    //screen width-----------------------------------------------------
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [activeNav, updateactiveNav] = useState(null)

    // Update screen width when the window is resized
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    //signin form submit-------------------------------
    const [signinValues, setsigninValues] = useState({
        email: "",
        password: "",
    });
    const [isLoadingBtn, updateisLoadingBtn] = useState(null)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const signinSubmit = async (e) =>{
        e.preventDefault();
        updateisLoadingBtn(true)
        setIsButtonDisabled(true)

        try {
            //send form data to backend
            const signInResp =  await axios.post('/api/signin', {...signinValues})
            const signIndata = signInResp.data;
    
            //checks if data contains any errors
            if(signIndata.errMsg){
                toast.error(signIndata.errMsg, {
                  position:"top-center",
                  autoClose: 5000,
                  hideProgressBar:false,
                  newestOnTop:true,
                  closeOnClick:true,
                  rtl:false,
                  pauseOnFocusLoss:false,
                  draggable:false,
                  pauseOnHover: false,   
                });
                window.scroll(0, 0)
                updateisLoadingBtn(false)
                setIsButtonDisabled(false)
            }else{
                updateisLoadingBtn(false)
                setIsButtonDisabled(false)
                sessionStorage.setItem('adminInfo', JSON.stringify(signIndata.token))
                navigate('/admin/dashboard'); // Navigate to the admin dashboard
    
            }
    
        }catch (error) {
            console.log(error)
            updateisLoadingBtn(false)
            setIsButtonDisabled(false)
        }     



    }

  return (
    <div className={` overflow-x-hidden ${activeNav ? 'fixed' : 'absolute'} w-full min-h-full text-black bg-[#FFFFFF]`}>
        
        <nav className='relative py-[2rem] h-[100px] w-full flex justify-between items-center bg-white md:px-[1rem]'>
            <img src = {logo} alt='logo' className='z-40  pl-2 max-w-[200px] max-h-full'/>

            {screenWidth > 891 && <div className='relative flex items-center space-x-8 pr-2'>
                <Link to = "/" className='text-black font-[550] transition-all duration-300 ease-in hover:text-UserOrageLight'>Home</Link>
                <Link to = "/aboutus" className='text-black font-[550] transition-all duration-300 ease-in hover:text-UserOrageLight'>About Us</Link>
                <Link to = "/parceltracking" className='text-black font-[550] transition-all duration-300 ease-in hover:text-UserOrageLight'>Parcel Tracking</Link>
                <Link to = "/customerlogin" className='text-black font-[550] transition-all duration-300 ease-in hover:text-UserOrageLight'>Customer Login </Link>
            </div>}

            {!activeNav && <button onClick={()=>updateactiveNav(true)} className='z-40 flex pr-2 md:hidden'>
                <HiMenuAlt3 className = 'text-[25px] text-[#222222]'/>
            </button>}

            {activeNav && <button onClick={()=>updateactiveNav(false)} className='z-40 flex pr-2 md:hidden'>
                <p className = 'z-40 text-[29px] font-[400] text-white'>x</p>
            </button>}
        </nav>

        <div className={`absolute z-30 h-screen w-screen ${activeNav ? 'top-[0]' : 'top-[-200vh]' } left-0 transition-top duration-500 ease-linear`}>
            <div className='relative h-[100%] w-full flex flex-col justify-start items-end space-y-[6rem] bg-black py-[120px] px-[1rem]'>
                <Link to="/" className='text-white'>Home</Link>
                <Link to="/aboutus" className='text-white'>About Us</Link>
                <Link to="/parceltracking" className='text-white'>Parcel Tracking</Link>
                <Link to="/customerlogin" className='text-white'>Customer Login</Link> 
            </div>
        </div>

        <div className='relative w-full min-h-[900px] px-[1rem] flex flex-col justify-start items-start pt-[6rem]'>

            <div className='relative'>
              <h1 className='text-black text-[22px] font-[550] pb-2'>Swiftlinecargos Customer Portal</h1>
              <p className='text-UserOrageLight pb-[3rem]'>Sign into your account.</p>
            </div>

            <div className='relative w-full flex justify-start items-center '>
                <ToastContainer  className="relative pb-4 max-w-[250px]"/>
            </div>

            <form action="" className='w-full h-full flex flex-col justify-center items-start space-y-6' onSubmit={(e) => signinSubmit(e)}>
                <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                    <label htmlFor="email" className='text-[15px] text-left text-black font-[450]'>Email</label>
                    <input type="email" name='email' className='px-2 border-[#d9d9d9] border-[1px] rounded-[2px] w-full h-[45px] text-black text-[16px] sm:w-[450px] focus:outline-none focus:border-UserOrageLight ' onChange={(e)=> setsigninValues({...signinValues, [e.target.name]:e.target.value})} />
                </div>

                <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                    <label htmlFor="password" className='text-[15px] text-left text-black font-[450]'>Password</label>
                    <input type="password" name='password' className='px-2 border-[#d9d9d9] border-[1px] rounded-[2px] w-full h-[45px] text-black text-[16px] sm:w-[450px] focus:outline-none focus:border-UserOrageLight ' onChange={(e)=> setsigninValues({...signinValues, [e.target.name]:e.target.value})} />
                </div>

                <button type='submit' disabled = {isButtonDisabled} className='bg-[rgb(29,29,29,0.80)] transition-colors duration-150 ease-linear text-white border-white rounded-md flex justify-center items-center text-center w-full h-[50px] hover:bg-[rgba(15,15,15,0.5)] sm:w-[450px]'>
                    {isLoadingBtn ? <img src={spinner} className='w-[50px] h-[50px]' alt='loading'  /> : <p>Sign In</p>} 
                </button>
            </form>

        </div>

    </div>
  )
}

export default CustomerLogin