import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from 'react-router-dom'
import {AiTwotoneMail} from "react-icons/ai" 
import {BiSolidPhone} from "react-icons/bi" 
import {GrClose} from "react-icons/gr"
import {HiMenuAlt3} from "react-icons/hi"
import {BiSolidQuoteAltRight} from "react-icons/bi"
import {BiSolidQuoteAltLeft} from "react-icons/bi"
import showcase1 from '../images/slider-img1.jpg'
import showcaseshape from '../images/header-shape.png'
import feature1 from '../images/features-icon1.png'
import feature2 from '../images/features-icon2.png'
import feature3 from '../images/features-icon3.png'
import logo from '../images/Logo.svg'
import aboutimg from '../images/about-1.jpg'
import feauturesbottom from '../images/features-sec-bottom-shape.svg'
import singleservice from '../images/single-service-shape.svg'
import waveshapebottom from '../images/wave-shape-bottom.png'
import waveshapetop from '../images/wave-shape-top.png'


const Landing = () => {

  //screen width-----------------------------------------------------
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [activeNav, updateactiveNav] = useState(null)

  // Update screen width when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // testtimonials-----------------------------------------------------

  useEffect(() => {
    changeQuote();
  }, []);

  const [quotes, updatequotes] = useState(['Incredible service! They delivered my cargo faster than I could have ever imagined. This company sets the gold standard for efficiency and reliability.', "I've used many cargo delivery services, but this one stands out. They make complex logistics look easy, and my shipments always arrive on time, without fail!", 'Top-notch professionals! This cargo delivery company combines speed and precision like no other. I trust them with my most critical shipments, and they never disappoint.', "Efficiency at its finest! From start to finish, this company gets it right every time. They've become my go-to choice for all cargo deliveries because of their consistent excellence."]);
  const [client, updateClient] = useState(['Daniel W.', 'Emma L.', 'Adam G.', 'Kim B.' ])
  const [dots, dotUpdate] = useState([1, 2, 3, 4]);
  const [currentquote, setCurrentquote] = useState(0);

  useEffect(()=>{
    const intervalId = setInterval(() => {
      setCurrentquote((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 6000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);

  }, [quotes]);

  const changeQuote = () =>{
       setCurrentquote((prevIndex) => (prevIndex + 1)  % quotes.length) 
  }

  //subscribe to newsletter------------------------------------------------
  const [subForm, upsubForm] = useState({
    newsletter : '',
  })

  const [showQuoteToast, upShowQuoteToast] = useState(null)

  const subNewsLetter = async () =>{
      upShowToast(false)
      upShowQuoteToast(true)
      

      try {
          const sendReq = await axios.post('/api/newsletter', {...subForm}) 
          const reqdata = sendReq.data;

          if(reqdata.successMsg){
              toast.success(reqdata.successMsg, {
                  position:"top-center",
                  autoClose: 5000,
                  hideProgressBar:false,
                  newestOnTop:true,
                  closeOnClick:true,
                  rtl:false,
                  pauseOnFocusLoss:false,
                  draggable:false,
                  pauseOnHover: false,   
              });
          }else{
              toast.error(reqdata.errMsg, {
                  position:"top-center",
                  autoClose: 5000,
                  hideProgressBar:false,
                  newestOnTop:true,
                  closeOnClick:true,
                  rtl:false,
                  pauseOnFocusLoss:false,
                  draggable:false,
                  pauseOnHover: false,   
              });         
          }
      } catch (error) {
          console.log(error)
      }
  }

  //get quotation form---------------------------------------------
  const [quoteForm, updateQuoteForm] = useState({
    name: '',
    email: '',
    phone: '',
    location: '',
    message: ''
  })

  const [showToast, upShowToast] = useState(null)
 

  const quoteSubmit = async () =>{
        upShowQuoteToast(false)
        upShowToast(true)
        
   
      try {
          const sendReq = await axios.post('/api/getquotation', {...quoteForm}) 
          const reqdata = sendReq.data;

          if(reqdata.successMsg){
              toast.success(reqdata.successMsg, {
                  position:"top-center",
                  autoClose: 5000,
                  hideProgressBar:false,
                  newestOnTop:true,
                  closeOnClick:true,
                  rtl:false,
                  pauseOnFocusLoss:false,
                  draggable:false,
                  pauseOnHover: false,   
              });
          }else{
              toast.error(reqdata.errMsg, {
                  position:"top-center",
                  autoClose: 5000,
                  hideProgressBar:false,
                  newestOnTop:true,
                  closeOnClick:true,
                  rtl:false,
                  pauseOnFocusLoss:false,
                  draggable:false,
                  pauseOnHover: false,   
              });         
          }
      } catch (error) {
          console.log(error)
      }
  }  

  //whatsapp link------------------
  const phoneNumber = '+447342518045';

  const openChat = () => {
    // Construct the WhatsApp chat link with the phone number
    const chatLink = `https://api.whatsapp.com/send?phone=${encodeURIComponent(phoneNumber)}`;

    // Open the chat link in a new window or tab
    window.open(chatLink, '_blank');
  };

  return (
    <div className={` overflow-x-hidden ${activeNav ? 'fixed' : 'absolute'} w-full min-h-full text-black bg-[#FFFFFF]`}>
        
         {/* showcase and nav */}
         <div className='relative w-full flex flex-col justify-start items-center min-h-[14rem] px-3 md:px-8'>
            <img src={showcase1} alt = 'slider1' className='absolute h-full w-screen'/>
            <img src = {showcaseshape} alt ='shape' className='absolute w-screen bottom-0'/>  
            <nav className='relative w-full pt-[1.5rem] flex flex-col justify-center items-start lg:w-[950px]'>
                <div className='relative pb-[1.5rem] w-full flex flex-col justify-center items-center space-y-4 border-b-[0.50px] border-b-[#e1e1e1]  md:space-y-0 md:flex-row md:justify-between'>
                    <div className=''>
                        <h1 className='text-center text-white'>Excellent Courier Services</h1>
                    </div>

                    <div className='relative flex flex-col justify-center items-center space-y-3 md:flex-row md:space-y-0 md:space-x-6'>
                        <button onClick={()=> console.log(1)} className='flex items-center space-x-1 text-white transition-all duration-300 ease-in hover:text-UserOrageLight'>
                          <BiSolidPhone className='text-[19px] '/>
                          <p className='text-[15px]'>-</p>
                        </button>

                        <a href='mailto:support@swiftlinecargos.com' className='flex items-center space-x-1 text-white transition-all duration-300 ease-in hover:text-UserOrageLight'>
                          <AiTwotoneMail className='text-[19px]'/>
                          <p className='text-[15px]' >support@swiftlinecargos.com</p>
                        </a>
                    </div>
                </div>

                <div className='relative py-[2rem] h-[100px] w-full flex justify-between items-center bg-white md:px-[1rem]'>
                    
                    <img src = {logo} alt='logo' className='pl-2 max-w-[200px] max-h-full'/>

                    {screenWidth > 891 && <div className='relative flex items-center space-x-8 pr-2'>
                      <Link to = "/" className='font-[550] transition-all duration-300 ease-in hover:text-UserOrageLight'>Home</Link>
                      <Link to = "/aboutus" className='font-[550] transition-all duration-300 ease-in hover:text-UserOrageLight'>About Us</Link>
                      <Link to = "/parceltracking" className='font-[550] transition-all duration-300 ease-in hover:text-UserOrageLight'>Parcel Tracking</Link>
                      <Link to = "/customerlogin" className='font-[550] transition-all duration-300 ease-in hover:text-UserOrageLight'>Customer Login </Link>
                    </div>}

                    {!activeNav && <button onClick={()=>updateactiveNav(true)} className='flex pr-2 md:hidden'>
                      <HiMenuAlt3 className = 'text-[25px] text-[#222222]'/>
                    </button>}

                    {activeNav && <button onClick={()=>updateactiveNav(false)} className='flex pr-2 md:hidden'>
                      <GrClose className = 'text-[19px] font-[650] text-[#222222]'/>
                    </button>}

                </div>   

                {activeNav && <div className='relative min-h-[300px] w-full flex flex-col justify-evenly items-end bg-white pr-2 transition-all ease-in duration-300 md:px-[1rem]'>
                      <Link to = "/" className='text-UserOrageLight transition-all duration-300 ease-in'>Home</Link>
                      <Link to = "/aboutus" className='text-UserOrageLight transition-all duration-300 ease-in'>About Us</Link>
                      <Link to = "/parceltracking" className='text-UserOrageLight transition-all duration-300 ease-in'>Parcel Tracking</Link>
                      <Link to = "/customerlogin" className='text-UserOrageLight transition-all duration-300 ease-in'>Customer Login </Link> 
                </div>}        
            </nav> 

            {/* showcase */}
            <div className='relative w-full min-h-[29rem] flex flex-col justify-center items-center space-y-4 md:pt-[10rem] md:justify-start md:min-h-[39rem]'>
                <h5 className='uppercase text-white text-center text-[15px] tracking-wider'>WeLCOME to swiftlinecargos</h5>
                <h5 className='capitalize text-[30px] text-center max-w-[600px] text-white tracking-wide md:text-[45px]'>we provide top courier & parcel services</h5>
                <Link to = "/parceltracking" className='capitalize text-white flex justify-center items-center text-center  w-[130px] h-[42px] bg-UserOrageLight border-UserOrageLight rounded-sm'>
                  Track package
                </Link>
            </div>

         </div>      
        
        {/* steps */}
        <div className='relative w-full flex flex-col justify-start items-center space-y-6 px-3 md:px-8 py-[3rem] md:space-y-0 md:space-x-6 md:flex-row md:justify-center'>   
            <div className='shadow-feature bg-[#fafafa] w-full min-h-[382px] flex flex-col justify-center items-center space-y-4 py-[1rem] sm:w-[80%] md:justify-start md:w-[350px]'>
                <div className='w-[90px] h-[90px] flex justify-center items-center bg-UserOrageLight border border-UserOrageLight rounded-[90px]'>
                    <img src = {feature1} alt='Apply' className='w-[54px] h-[50px]'/>
                </div>

                <h4 className='text-center font-[550] text-[17px]'>Apply Online</h4>

                <p className='text-[15px] text-center max-w-[80%]'>We understand that sending a package should be a seamless and hassle-free experience. That's why we've streamlined our process to make it as convenient as possible for you. One of the essential steps in this process is our user-friendly online application.</p>
            </div>

            <div className='shadow-feature bg-[#fafafa] w-full min-h-[382px] flex flex-col justify-center items-center space-y-4 py-[1rem] sm:w-[80%] md:justify-start md:w-[350px]'>
                <div className='w-[90px] h-[90px] flex justify-center items-center bg-UserOrageLight border border-UserOrageLight rounded-[90px]'>
                    <img src = {feature2} alt='Apply' className='w-[54px] h-[50px]'/>
                </div>

                <h4 className='text-center font-[550] text-[17px]'>Submit Documents</h4>

                <p className='text-[15px] text-center max-w-[80%]'>Use our hassle-free submission system, ensuring your paperwork reaches its destination with ease and efficiency.</p>
            </div>

            <div className='shadow-feature bg-[#fafafa] w-full min-h-[382px] flex flex-col justify-center items-center space-y-4 py-[1rem] sm:w-[80%] md:justify-start md:w-[350px]'>
                <div className='w-[90px] h-[90px] flex justify-center items-center bg-UserOrageLight border border-UserOrageLight rounded-[90px]'>
                    <img src = {feature3} alt='Apply' className='w-[54px] h-[50px]'/>
                </div>

                <h4 className='text-center font-[550] text-[17px]'>Receive Goods</h4>

                <p className='text-[15px] text-center max-w-[80%]'>We believe that the joy of receiving goods should rival the excitement of unwrapping a gift. A key crescendo in this harmonious journey is our brilliantly designed, user-friendly goods reception platform.</p>
            </div>
        </div> 

        {/* who we are */}
        <div className='relative w-full flex flex-col justify-start items-center space-y-[6rem] px-3 md:px-8 py-[3rem] md:space-y-0 md:space-x-6 md:flex-row md:justify-center'> 
            <div className='relative flex justify-center w-full md:justify-end md:w-1/2'>
              <div className='w-[485px] h-[530px]'>
                  <img src={aboutimg} alt='goods' className='w-[485px] h-[530px]'
                  style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                  />
              </div>

              <div className='absolute'>

              </div>
            </div>

            <div className='relative flex flex-col space-y-4 w-full md:w-1/2'>
              <p className='text-[16px] text-[#2D2E2E] '>Who are Swiftlinecargos</p>
              <h3 className='text-[35px] font-[600] text-[#2D2E2E]'>Best Courier & Parcel Services</h3>
              <div className='w-[40px] h-[3px] bg-UserOrageLight mt-[0.50rem]'></div>
              <p className='max-w-[880px] md:max-w-[600px]'>Swiftlinecargos is one of the fastest growing logistics service providers delivering goods 7 days a week across the UK, Europe, America and Asia. Global industry experts view our growth as a phenomenal success story. From launching with a single depot and ambitious goals in 2012, we delivered over 71 million orders with a 99.17% reliability record in 2023 to support society in a time of need. And we're not stopping there.</p>
            </div>
        </div>

        {/* company facts */}
        <img alt='feauture-img' src= {feauturesbottom}  
            className='relative w-full h-[57px] top-0 left-0'
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
        />

        <div className='relative w-full min-h-[100px] flex justify-center items-center px-3 bg-[#f8f8f8] md:px-8'>
            <div className='relative pb-[2rem] w-full min-h-[200px] grid grid-cols-1 gap-[2rem] justify-items-center items-center sm:w-[80%] sm:grid-cols-2 md:grid-cols-4'>
                <div className='flex flex-col justify-center items-center space-y-2'>
                  <p className='text-[#7a7e9a]'>Satisfied Clients</p>
                  <h3 className='text-[46px] text-UserOrageLight font-[550] tracking-widest'>$500M</h3>
                </div>

                <div className='flex flex-col justify-center items-center space-y-2'>
                  <p className='text-[#7a7e9a]'>Countries Covered</p>
                  <h3 className='text-[46px] text-UserOrageLight font-[550] tracking-widest'>45+</h3>
                </div>

                <div className='flex flex-col justify-center items-center space-y-2'>
                  <p className='text-[#7a7e9a]'>Offices Worldwide</p>
                  <h3 className='text-[46px] text-UserOrageLight font-[550] tracking-widest'>107</h3>
                </div>

                <div className='flex flex-col justify-center items-center space-y-2'>
                  <p className='text-[#7a7e9a]'>Reviews</p>
                  <h3 className='text-[46px] text-UserOrageLight font-[550] tracking-widest'>20K</h3>
                </div>
            </div>
        </div>

        {/* our services */}
        <div className='relative pt-[5rem] w-full flex flex-col justify-center items-center space-y-2 px-3 md:px-8'>
          <h2 className='font-[600] text-[30px]'>Our Services</h2>
          <p className='text-center max-w-[600px]'>We specialize in various sea freight solutions, considering the individual wishes of our customers. We tailor our solutions around your unique requirements to deliver your cargo to any global address.</p>
        </div>

        <div className='relative w-full flex justify-center items-center px-3 md:px-8'>
            <div className='relative py-[3rem] w-full min-h-[350px] flex flex-col justify-center items-center space-y-4 md:w-[70%] md:space-y-0 md:flex-none md:grid md:grid-cols-3 md:justify-items-center md:gap-[1rem]'>
                <div className='relative py-5 px-2 shadow-feature w-full min-h-[300px] bg-white flex flex-col justify-start items-center space-y-2 '>       
                    <div className='w-[70px] h-[70px] flex justify-center items-center border border-UserOrageLight rounded-[70px]'>
                        <p className='text-[21px] font-[550]'>01</p>
                    </div>

                    <h3 className='text-[23px] font-[550] text-center pt-[0.40rem]'>Standard Courier</h3>
                    <p className='text-[14px] font-[500] text-center'>Our standard courier service provides reliable and efficient delivery options, ensuring your packages reach their destination on time, every time.</p>
                    <img src={singleservice} className='absolute w-full bottom-0' alt='singleservice'/>
                </div>

                <div className='relative py-5 px-2 shadow-feature w-full min-h-[300px] bg-white flex flex-col justify-start items-center space-y-2 '>       
                    <div className='w-[70px] h-[70px] flex justify-center items-center border border-UserOrageLight rounded-[70px]'>
                        <p className='text-[21px] font-[550]'>02</p>
                    </div>

                    <h3 className='text-[23px] font-[550] text-center pt-[0.40rem]'>Door to Door</h3>
                    <p className='text-[14px] font-[500] text-center'>Our door-to-door service offers the convenience of pick-up and delivery right at your doorstep, ensuring a hassle-free shipping experience.</p>
                    <img src={singleservice} className='absolute w-full bottom-0' alt='singleservice'/>
                </div>

                <div className='relative py-5 px-2 shadow-feature w-full min-h-[300px] bg-white flex flex-col justify-start items-center space-y-2 '>       
                    <div className='w-[70px] h-[70px] flex justify-center items-center border border-UserOrageLight rounded-[70px]'>
                        <p className='text-[21px] font-[550]'>03</p>
                    </div>

                    <h3 className='text-[23px] font-[550] text-center pt-[0.40rem]'>Express Courier</h3>
                    <p className='text-[14px] font-[500] text-center'>Our express courier service ensures swift and secure delivery, perfect for time-sensitive shipments that demand quick transit.</p>
                    <img src={singleservice} className='absolute w-full bottom-0' alt='singleservice'/>
                </div>

                <div className='relative py-5 px-2 shadow-feature w-full min-h-[300px] bg-white flex flex-col justify-start items-center space-y-2 '>       
                    <div className='w-[70px] h-[70px] flex justify-center items-center border border-UserOrageLight rounded-[70px]'>
                        <p className='text-[21px] font-[550]'>04</p>
                    </div>

                    <h3 className='text-[23px] font-[550] text-center pt-[0.40rem]'>Ware House</h3>
                    <p className='text-[14px] font-[500] text-center'>Our warehouse services provide secure storage and inventory management solutions, ensuring your goods are handled with care.</p>
                    <img src={singleservice} className='absolute w-full bottom-0' alt='singleservice'/>
                </div>

                <div className='relative py-5 px-2 shadow-feature w-full min-h-[300px] bg-white flex flex-col justify-start items-center space-y-2 '>       
                    <div className='w-[70px] h-[70px] flex justify-center items-center border border-UserOrageLight rounded-[70px]'>
                        <p className='text-[21px] font-[550]'>05</p>
                    </div>

                    <h3 className='text-[23px] font-[550] text-center pt-[0.40rem]'>Pallet</h3>
                    <p className='text-[14px] font-[500] text-center'>Our pallet shipping service is designed for bulk cargo, providing cost-effective and efficient transportation for larger shipments.</p>
                    <img src={singleservice} className='absolute w-full bottom-0' alt='singleservice'/>
                </div>

                <div className='relative py-5 px-2 shadow-feature w-full min-h-[300px] bg-white flex flex-col justify-start items-center space-y-2 '>       
                    <div className='w-[70px] h-[70px] flex justify-center items-center border border-UserOrageLight rounded-[70px]'>
                        <p className='text-[21px] font-[550]'>06</p>
                    </div>

                    <h3 className='text-[23px] font-[550] text-center pt-[0.40rem]'>International Courier</h3>
                    <p className='text-[14px] font-[500] text-center'> Our international courier service ensures seamless cross-border shipping, connecting you to global markets with ease.</p>
                    <img src={singleservice} className='absolute w-full bottom-0' alt='singleservice'/>
                </div>
            </div>
        </div>

        {/* get quotation */}
        <div className='relative my-[5rem] py-[4rem] bg-[#ebf5ff] min-h-[400px] w-full flex flex-col justify-center items-center space-y-2 px-3 md:px-8'>
            <h3 className='text-center font-[550] text-[30px]'>Get Quotation</h3>
            <p className='text-center max-w-[500px] pb-[2.5rem]'>Fill the form below and we will get back to you.</p>
            
            <div className='relative w-full flex justify-center items-center'>
                {showToast ? <ToastContainer  className="relative pb-4 max-w-[250px]"/> : ''}
            </div>
            
            <div className='w-full py-[2rem] px-4 bg-white min-h-[350px] flex flex-col space-y-3 sm:w-[500px]'>
                <div className='flex flex-col space-y-3 sm:space-y-0 sm:flex-row sm:space-x-2'>
                    <input type='text' onChange={(e)=>updateQuoteForm({...quoteForm, [e.target.name]:e.target.value})} value={quoteForm.name} name='name'  className='w-full px-2 min-h-[50px] border border-[#ebf5ff] rounded-md focus:outline-none sm:max-w-[500px]' placeholder='Name'/>
                    <input type='email' onChange={(e)=>updateQuoteForm({...quoteForm, [e.target.name]:e.target.value})} value={quoteForm.email} name='email' className='w-full px-2 min-h-[50px] border border-[#ebf5ff] rounded-md focus:outline-none sm:max-w-[500px]' placeholder='Email'/>
                </div>

                <div className='flex flex-col space-y-3 sm:space-y-0 sm:flex-row sm:space-x-2'>
                    <input type='tel' onChange={(e)=>updateQuoteForm({...quoteForm, [e.target.name]:e.target.value})} value={quoteForm.phone} name='phone' className='w-full px-2 min-h-[50px] border border-[#ebf5ff] rounded-md focus:outline-none sm:max-w-[500px]' placeholder='Phone'/>
                    <input type='text' onChange={(e)=>updateQuoteForm({...quoteForm, [e.target.name]:e.target.value})} value={quoteForm.location} name='location' className='w-full px-2 min-h-[50px] border border-[#ebf5ff] rounded-md focus:outline-none sm:max-w-[500px]' placeholder='Location'/>
                </div>

                <textarea name='message' onChange={(e)=>updateQuoteForm({...quoteForm, [e.target.name]:e.target.value})} value={quoteForm.message} className='w-full p-2 min-h-[170px] border border-[#ebf5ff] rounded-md resize-none focus:outline-none' placeholder='Message'/>

                <div className='relative w-full flex justify-center items-center'>
                  <button onClick={()=> quoteSubmit()} className='text-[14px] w-[130px] px-2 h-[45px] border border-UserOrageLight rounded-sm text-white bg-UserOrageLight'>Submit</button>
                </div>
            </div>

            <img src={waveshapebottom} className='absolute h-[20px] w-full bottom-0' alt='waveshapebottom'/>
            <img src={waveshapetop} className='absolute h-[20px] w-full top-[-10px]' alt='waveshapetop'/>
        </div>

        {/* testimonials */}
        <div className='relative my-[5rem] w-full min-h-[100px] flex flex-col justify-center items-center px-3 md:px-8 '>
            <h3 className='text-center capitalize font-[550] text-[30px] pb-[0.50rem]'>Hear from our clients</h3>
            <p className='text-center max-w-[400px] pb-[2.5rem]'>Here are a few testimonies from our customers.</p> 

            <div className='shadow-feature w-full p-4 bg-white border border-white rounded-md min-h-[250px] flex flex-col justify-center items-center space-y-3 sm:w-[500px]'>
                <div className='relative w-full flex justify-center items-center space-x-2'>
                  <BiSolidQuoteAltLeft className='text-[28px] text-[#e2e2e2]'/>
                  <BiSolidQuoteAltRight className='text-[28px] text-[#e2e2e2]'/>                
                </div>

                <div className='flex flex-col justify-center items-center space-y-4'>
                    <p className='max-w-[350px] text-center'>{quotes[currentquote]}</p>
                    <h3 className='text-[20px] font-[550]'>{client[currentquote]}</h3>
                </div>

                <div className='relative flex justify-center items-center space-x-2 pt-[0.50rem]'>
                  <div className={`w-[15px] h-[15px] ${currentquote === 0 ? 'bg-[#2f2f2f]': 'bg-[#e2e2e2]'} border-inherit border rounded-full`}></div>
                  <div className={`w-[15px] h-[15px] ${currentquote === 1 ? 'bg-[#2f2f2f]': 'bg-[#e2e2e2]'} border-inherit border rounded-full`}></div>
                  <div className={`w-[15px] h-[15px] ${currentquote === 2 ? 'bg-[#2f2f2f]': 'bg-[#e2e2e2]'} border-inherit border rounded-full`}></div>
                  <div className={`w-[15px] h-[15px] ${currentquote === 3 ? 'bg-[#2f2f2f]': 'bg-[#e2e2e2]'} border-inherit border rounded-full`}></div>
                </div>
            </div>
        </div>

        <footer className='bg-[#ebf5ff] relative mt-[5rem] w-full min-h-[230px] py-6 px-3 pb-[5rem] md:px-8 '>
            <div className='relative w-full min-h-full flex flex-col justify-start items-start space-y-6 md:flex-row md:justify-evenly md:space-x-4 md:items-start md:space-y-0'>
                <div className='relative w-full flex flex-col space-y-4 max-w-[400px]'>
                    <img src = {logo} alt='logo' className='max-w-[150px] max-h-full'/>
                    <p className='max-w-[410px]'>Swiftlinecargos is one of the fastest growing logistics service providers delivering goods 7 days a week across the UK, Europe, America and Asia.</p>
                </div>

                <div className='relative flex flex-col space-y-4'>
                    <h4 className='text-[22px] font-[550]'>Useful Links</h4>

                    <div className='flex flex-col space-y-2'>
                        <Link to = "/">Home</Link>
                        <Link to = "/aboutus">About Us</Link>
                        <Link to = "/parceltracking">Parcel Tracking</Link>
                        <Link to = "/customerlogin">Customer Login</Link>
                    </div>
                </div>

                <div className='relative flex flex-col space-y-4'>
                    <h4 className='text-[22px] font-[550]'>Subscribe Newsletter</h4>

                    <p className='max-w-[300px]'>By subscribing to our mailing list you will always be update with the latest news from us.</p>

                    <div className='relative w-full flex justify-start items-start'>
                        {showQuoteToast ? <ToastContainer  className="relative pb-4 max-w-[250px]"/> : ''}
                    </div>

                    <div className='flex space-x-2'>  
                      <input type="text" name='newsletter' onChange={(e)=> upsubForm({...subForm, [e.target.name]:e.target.value})} placeholder='Enter Email Address' className='bg-mainBackColor px-2 border-[#d9d9d9] border-[1px] rounded-[5px] w-full h-[45px] text-black text-[16px] focus:outline-none focus:border-UserOrageLight' />
                      <button onClick={()=> subNewsLetter()} className='text-[14px] max-w-[130px] px-2 h-[45px] border border-UserOrageLight rounded-sm text-white bg-UserOrageLight'>Subscribe</button>
                    </div>
                </div>
            </div>

            <div className='relative border-t border-t-[#dbdee2] w-full h-[2px] pt-[4rem] mt-[3rem] flex flex-col justify-center items-center space-y-4 md:flex-row md:space-x-6 md:space-y-0'>
                <p className='text-[14px] text-[#303030] text-center'>© 2023 All Rights Reserved by Swiftlinecargos</p>
                <p className='text-[14px] text-[#303030]'>Terms & Conditions</p>
            </div>

        </footer>

  
    </div>
  )
}

export default Landing