import React, { useContext } from 'react';
import axios from 'axios';
import { useState  } from 'react'

const AppContext = React.createContext();

const AppProvider = ({ children }) => {

  //users
  const [trackingInfoUser, updateTrackingInfoUser] = useState([])
  const [userPayInfo, updateUserPayInfo] = useState([])

  //admin
  const [allAminData, setallAminData] = useState()

  const fetchAdminData = async () =>{
      try {
        // Get the token from sessionStorage
        const adminToken = sessionStorage.getItem('adminInfo');

        // Include the token in the request headers
        const adminConfig = {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        };

        //fetch all admin data 
        const adminDash = await axios.get('/api/admin/dashboard', adminConfig);
        const adminDashData = adminDash.data.allData;

        setallAminData(adminDashData); 

      } catch (error) {
        console.log(error)
      }

  }

  const fetchUserData = async () => {
    try {
        const userToken = sessionStorage.getItem('userInfo');

        const userConfig = {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        };

        // Fetch tracking data for user
        const PackageResponse = await axios.get('/api/user/getUserPackage', userConfig);
        const PackageData = PackageResponse.data.user;

        updateTrackingInfoUser(PackageData);         

        // Fetch payment data for user
        const paymentResponse = await axios.get('/api/user/paymentinfo', userConfig);
        const PaymentData = paymentResponse.data.user;

        updateUserPayInfo(PaymentData);  

    } catch (error) {
      console.log(error)
    }
  }


    return <AppContext.Provider value={{
      fetchUserData,
      userPayInfo,
      trackingInfoUser,
     

      fetchAdminData,
      allAminData,
    }}>{children}</AppContext.Provider>;
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { useGlobalContext, AppProvider };