import React, { useState } from 'react'
import {Link, useNavigate} from "react-router-dom"
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { IoCopyOutline } from "react-icons/io5";
import { ImArrowLeft2 } from "react-icons/im";

import btcImage from '../../images/btc.svg'
import ethImage from '../../images/eth.svg'
import checkImg from '../../images/check.png'


const CryptoPayment = () => {
    const navigate = useNavigate();

    const [activeCoinAddress, updateActiveCoinAddress] = useState('');
    const [coinCopied, updateCopiedCoin] = useState('');
    const [isCopyActive, updateCopyActive] = useState(false);

  return (
    <div className='absolute overflow-hidden pt-[1.5rem] flex flex-col justify-start items-center space-y-[1rem] w-full min-h-full text-white bg-white px-3 py-2 md:px-8'>
          
            <nav className='relative w-full min-h-[50px] flex justify-between items-center sm:max-w-[450px]'>
                
                <button onClick={() => navigate('/user/paymentinfo')} className='p-1 flex justify-start items-center space-x-2'>
                    <ImArrowLeft2 className='font-[550] text-[#282828]'/>
                    <p className='text-[16px] text-[#282828] font-[550]'>go back</p>
                </button>
            
            </nav>

            <div className='relative p-4 w-full flex flex-col justify-start items-start space-y-[1rem] min-h-[330px] bg-[#F7F7F7] border border-[#F7F7F7] rounded-[20px] sm:max-w-[450px]'>
                <h3 className='pt-2 tracking-wide text-[17px] font-[550] text-[#282828] '>Select Cryptocurrency</h3>

                <CopyToClipboard  text={'bc1qhugj8xyj7acvmzxvlu3uug8dyxcfvctuarhezs'}>
                    <button onClick={()=> {updateActiveCoinAddress('BTC'); updateCopiedCoin('bc1qhugj8xyj7acvmzxvlu3uug8dyxcfvctuarhezs'); updateCopyActive(true);}} className='px-3 py-2 w-full min-h-[70px] flex items-center space-x-3 bg-white border border-white rounded-[100px] transition-all hover:border-[#d6d6d6] '>
                    
                            <div className='shrink-0'>
                                    <img className='w-[30px] h-[30px]' src = {btcImage} alt='bitcoin' />
                            </div>

                            <div className='flex flex-col w-[95%] grow'>
                                    <h4 className='text-[17px] tracking-wide font-[550] text-[#212020] text-left'>Bitcoin</h4>

                                    <div className='flex space-x-1 w-[80%] ssm:w-[85%] sm:w-[100]'>
                                        <p className='max-w-[90%] text-left text-[#777f89] min-h-[30px] text-ellipsis  text-[15px] '>bc1qhugj8xyj7acvmzxvlu3uug8dyxcfvctuarhezs</p>

                                        <button className='w-[10%] flex justify-end'>
                                            <IoCopyOutline className='text-[18px] text-gray-500'/>
                                        </button>
                                    </div>
                                
                            </div>

                    </button>
                </CopyToClipboard>

                <CopyToClipboard  text={'0xFD0c51a1A3dB6f29a20149a50b27c7de5B67C373'}>
                    <button onClick={()=> {updateActiveCoinAddress('ETH'); updateCopiedCoin('0xFD0c51a1A3dB6f29a20149a50b27c7de5B67C373'); updateCopyActive(true);}} className='px-3 py-2 w-full min-h-[70px] flex items-center space-x-3 bg-white border border-white  rounded-[100px] transition-all hover:border-[#d6d6d6] '>
                    
                            <div className='shrink-0'>
                                    <img className='w-[30px] h-[30px]' src = {ethImage} alt='ethereum' />
                            </div>


                            <div className='flex flex-col w-[95%] grow'>
                                    <h4 className='text-[17px] tracking-wide font-[550] text-[#212020] text-left'>Ethereum</h4>

                                    <div className='flex space-x-1 w-[80%] ssm:w-[85%] sm:w-[100]'>
                                        <p className='max-w-[90%] text-left text-[#777f89] min-h-[30px] text-ellipsis  text-[15px] '>0xFD0c51a1A3dB6f29a20149a50b27c7de5B67C373</p>

                                        <button className='w-[10%] flex justify-end'>
                                            <IoCopyOutline className='text-[18px] text-gray-500'/>
                                        </button>
                                    </div>
                                    
                            </div>

                    </button>
                </CopyToClipboard>

                {isCopyActive && <div className='px-4 py-1 w-full min-h-[2rem] flex items-center space-x-3 bg-[#ffffff] border border-white rounded-[9px]'>
                    <div className='shrink-0'>
                            <img  src = {checkImg} alt='check' />
                    </div>

                    <div className='flex flex-col max-w-[90%] min-h-[1rem]'>
                            <p className='text-[#777f89] text-[14.50px]'>{activeCoinAddress} Address Copied</p>
                            <p className='text-[#9ea5ad] text-[13px]  break-words whitespace-normal max-w-full'>{coinCopied}</p>
                    </div>
                </div>}
        </div>

    </div>
  )
}

export default CryptoPayment