import React from 'react';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {FiCopy} from "react-icons/fi" 
import {BsChevronDown} from "react-icons/bs" 
import {FaBitcoin} from "react-icons/fa" 
import {FaEthereum} from "react-icons/fa" 
import {BiSolidDollarCircle} from "react-icons/bi"

const Paymentlink = () => {

  //wallet copy-----------------
  const [isOpen, setIsOpen] = useState(false);
  const [showCopy, UpdateshowCopy] = useState(null);
  const [selectedOption, setSelectedOption] = useState(                                        
    <li className='px-4 text-[13px] py-2 hover:bg-[#151618] flex space-x-2 justify-start items-center'>
        <FaBitcoin className='text-yellow-600 shrink-0' />
        <p>BTC</p> 
    </li>
  ); 

  const [walletAdd, UpdateWallet] = useState('bc1q3wjngp8z3ekx75dk38tr8p5a0qz4zlewl5w7dv');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const copyFunc = () =>{
    UpdateshowCopy(true);

    setTimeout(()=>{
        UpdateshowCopy(false);
    }, 2000)
  }


  return (
    <div className='absolute overflow-hidden w-full min-h-full text-white bg-black'>
        
        <div className='relative w-full h-full p-4 overflow-hidden mb-[100px]'> 
                      
            <div className='relative w-full flex flex-col justify-center items-center space-y-8  pt-[6rem]'>
              
                <h3 className='text-[23px] text-[#d4d3d3] capitalize text-center tracking-wider font-[550] pb-[5rem]'>cryptocurrency Payment Link</h3>

                <div className='w-full flex justify-center items-center space-x-4 sm:w-[400px]'> 
                    
                    <div className='max-w-[200px] min-h-[20px] py-2 px-5 bg-[rgb(24,28,38,0.70)] flex justify-center items-center border border-black rounded-[5px] '>
                        <p className='text-[13px]'>Select Asset</p>
                    </div>              
                    
                </div>
                
                <div className='w-full h-full min-h-[130px] flex flex-col items-center justify-between bg-[#181C26] p-6 border border-black rounded-xl sm:w-[400px] md:w-[600px]'>
                    {/* currency type */}
                    <div className='relative w-full flex justify-between items-center '> 
                        <p className='text-[13px] text-[#868e98] w-[31%]'>Asset</p>

                        <div className='relative w-[69%]'>
                            <button onClick={toggleDropdown} className='w-full bg-inherit text-white text-[13px] flex justify-between items-center '>
                                <div className='text-[13px] text-white text-ellipsis'>{selectedOption}</div>  
                                <BsChevronDown className='text-[#868e98] text-[14px] flex-shrink-0'/>
                            </button>
                            {isOpen && (
                                <ul className='absolute max-w-[200px] top-[-10px] z-50 left-[-5px] bg-black border border-black rounded-md ssm:w-[200px]'>
                                    <li onClick={() => {handleOptionClick(
                                        <li className='px-4 text-[13px] py-2 flex space-x-2 justify-start items-center'>
                                            <FaBitcoin className='text-yellow-600 shrink-0' />
                                            <p>BTC</p> 
                                        </li>
                                    ); UpdateWallet('bc1q3wjngp8z3ekx75dk38tr8p5a0qz4zlewl5w7dv'); } }
                                    className='px-4 text-[13px] py-2 hover:bg-[#151618] flex space-x-2 justify-start items-center'>
                                       <FaBitcoin className='text-yellow-600 shrink-0' />
                                       <p>BTC</p> 
                                    </li>

                                    <li onClick={() => { handleOptionClick(
                                        <li className='px-4 text-[13px] py-2 flex space-x-2 justify-start items-center'>
                                            <FaEthereum className='text-blue-400 text-[15px] shrink-0' />
                                            <p>ETH</p> 
                                        </li>
                                    ); UpdateWallet('0xB797E9a2d3C10EFb4C53F0319009aB4538dDE5D4'); } }
                                    className='px-4 text-[13px] py-2 hover:bg-[#151618] flex space-x-2 justify-start items-center'>
                                       <FaEthereum className='text-blue-400 text-[15px] shrink-0' />
                                       <p>ETH</p> 
                                    </li>

                                    <li onClick={() => {handleOptionClick(
                                        <li className='px-4 text-[13px] py-2  flex space-x-2 justify-start items-center'>
                                            <BiSolidDollarCircle className='text-[#868e98] text-[15px] shrink-0' />
                                            <p>USDT(ERC20)</p> 
                                        </li>
                                    ); UpdateWallet('0xB797E9a2d3C10EFb4C53F0319009aB4538dDE5D4'); } }
                                    className='px-4 text-[13px] py-2 hover:bg-[#151618] flex space-x-2 justify-start items-center'>
                                       <BiSolidDollarCircle className='text-[#868e98] text-[15px] shrink-0' />
                                       <p>USDT(ERC20)</p> 
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>

                    <div className='w-full h-[0.50px] bg-[#2B2B2B]'></div>

                    {/* address */}
                    <div className='relative w-full flex justify-center items-center space-x-2 '> 
                        <p className='text-[13px] text-[#868e98] w-[35%]'>Wallet Address</p>

                        <div className='w-[65%] h-full flex justify-between items-center space-x-4'> 
                          <p className='text-[13px] text-white text-ellipsis '>{walletAdd}</p>
                          <FiCopy className='text-[#868e98] text-[14px] flex-shrink-0'/>
                        </div>
                    </div>
                </div>
                
                <div className='w-full sm:w-[400px] flex flex-col justify-center items-center space-y-2'>
                    <CopyToClipboard onCopy={copyFunc} text={walletAdd}>
                        <button className='relative text-white text-[14px] w-full min-h-[40px] flex justify-center items-center space-x-2 p-2 border border-black rounded-md transition-all ease-in duration-200 bg-[#352e97] hover:bg-[#4239bc] sm:w-[400px] md:w-[600px]'>
                            <p className='text-[13px]'>Copy Address</p>   
                            <FiCopy className='text-white text-[14px] flex-shrink-0'/>                      
                        </button>
                    </CopyToClipboard>    

                    {showCopy && <div className='w-full min-h-[40px] sm:w-[400px] px-4 bg-[#191919] flex justify-center space-x-5 items-center border border-black rounded-md'>
                        <p className='text-[13px] text-[#868e98] whitespace-nowrap '>Address Copied</p>
                        <p className='text-[13px] text-[#868e98] text-ellipsis '>{walletAdd}</p>
                    </div>}
                </div>
         

            </div>            
      
        </div>   
    </div>
  )
}

export default Paymentlink