import React, { useState, useEffect } from 'react'
import {Link, useNavigate} from "react-router-dom"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import spinner from "../images/spinner.svg";



const CreateAdmin = () => {

    const navigate = useNavigate();

    //tracking form submit-------------------------------
    const [createAdmin, setcreateAdmin] = useState({
        email: "",
        password: ""
    });
    const [isLoadingBtn, updateisLoadingBtn] = useState(null)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const subMitform = async (e) =>{
        e.preventDefault();
        updateisLoadingBtn(true)
        setIsButtonDisabled(true)

        try {
            const sendReq = await axios.post('/api/createadmin', {...createAdmin}) 
            const reqdata = sendReq.data;

            if(reqdata.errMsg){
                toast.error(reqdata.errMsg, {
                    position:"top-center",
                    autoClose: 5000,
                    hideProgressBar:false,
                    newestOnTop:true,
                    closeOnClick:true,
                    rtl:false,
                    pauseOnFocusLoss:false,
                    draggable:false,
                    pauseOnHover: false,   
                });
                window.scroll(0, 0)
                updateisLoadingBtn(false)
                setIsButtonDisabled(false)
            }else{
                updateisLoadingBtn(false)
                setIsButtonDisabled(false)
                navigate('/customerlogin')
            }
        } catch (error) {
            console.log(error)
            updateisLoadingBtn(false)
            setIsButtonDisabled(false)
        }
    }


  return (

    <div className={` overflow-x-hidden absolute w-full min-h-full text-black bg-[#FFFFFF]`}>
        
        <div className='relative w-full min-h-[900px] px-[1rem] flex flex-col justify-start items-start pt-[6rem]'>
            
            <div className='relative w-full flex justify-center items-center px-4'>
                <ToastContainer  className="relative pb-4"/>
            </div>
         
            <form action="" className='w-full h-full flex flex-col justify-center items-start space-y-6' onSubmit={(e) => subMitform(e)}>
                <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                    <label htmlFor="email" className='text-[15px] text-left text-black font-[450]'>Email</label>
                    <input type="email" name='email' className='px-2 border-[#d9d9d9] border-[1px] rounded-[2px] w-full h-[45px] text-black text-[16px] sm:w-[450px] focus:outline-none focus:border-UserOrageLight ' onChange={(e)=> setcreateAdmin({...createAdmin, [e.target.name]:e.target.value})} />
                </div>

                <div className='relative w-full flex flex-col justify-start items-start space-y-2 '>
                    <label htmlFor="password" className='text-[15px] text-left text-black font-[450]'>Password</label>
                    <input type="password" name='password' className='px-2 border-[#d9d9d9] border-[1px] rounded-[2px] w-full h-[45px] text-black text-[16px] sm:w-[450px] focus:outline-none focus:border-UserOrageLight ' onChange={(e)=> setcreateAdmin({...createAdmin, [e.target.name]:e.target.value})} />
                </div>

                <button type='submit' disabled = {isButtonDisabled} className='bg-[rgb(29,29,29,0.80)] transition-colors duration-150 ease-linear text-white border-white rounded-md flex justify-center items-center text-center w-full h-[50px] hover:bg-[rgba(15,15,15,0.5)] sm:w-[450px]'>
                    {isLoadingBtn ? <img src={spinner} className='w-[50px] h-[50px]' alt='loading'  /> : <p>Create User</p>} 
                </button>
            </form>
            
        </div>

    </div>
  )
}

export default CreateAdmin