import React from 'react';
import {FaArrowLeft} from "react-icons/fa";
import { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useGlobalContext } from '../../context';
import { ImArrowLeft2 } from "react-icons/im";
import {Link, useNavigate} from "react-router-dom"
import cryptoPaymentImage from '../../images/coinpayments.webp';
import cardPaymentImage from '../../images/card.webp';
import paypalPaymentImage from '../../images/paypal.webp'; 
import loadingblack from '../../images/loadingblack.svg'; 


const PaymentInfo = () => {

    const navigate = useNavigate();
    const{userPayInfo} = useGlobalContext();

    const [paymentData, updatePaymentData] = useState({})

    useEffect(()=>{
        if(userPayInfo){
            updatePaymentData(userPayInfo)
        }
    }, [ userPayInfo])


    const [activeDeposit, updateactiveDeposit] = useState(false);
    const [paypalLoading, updatePaypaLoading] = useState(false);
    const [cardLoading, updateCardLoading] = useState(false);
    const [serviceUnavailable, updateServiceUnavailable] = useState(false);


    // const [btccopied, updatebtccopied] = useState(null)
    // const [ethcopied, updateethcopied] = useState(null)

    const togglePayment = () => {
        updateactiveDeposit(!activeDeposit)
    }

    const updatePaypaLoadingFunc = () => {
        updatePaypaLoading(true)

        setTimeout(()=>{
            updateServiceUnavailable(!serviceUnavailable)
            updatePaypaLoading(false) 
        }, 3000)

        setTimeout(()=>{
            updateServiceUnavailable(false) 
        }, 7000)
    }

    const updateCardLoadingFunc = () => {
        updateCardLoading(true)

        setTimeout(()=>{
            updateServiceUnavailable(!serviceUnavailable)
            updateCardLoading(false) 
        }, 3000)

        setTimeout(()=>{
            updateServiceUnavailable(false) 
        }, 7000)
    }

    const updateCryptoLoadingFunc = () => {
        navigate('/user/PaymentInfo/cryptopayment')
    }

    

  return (
    <div className='absolute overflow-hidden w-full min-h-full text-white bg-[#efefef] px-3 py-2 md:px-8'>

        <nav className='relative w-full min-h-[50px] flex justify-start items-center'>
            <Link to = "/user/packageinfo" className='flex justify-center items-center w-[35px] h-[35px] bg-[#bfbfbf] rounded-[35px]'>
                < FaArrowLeft className='w-[20px] h-[20px] text-black'/>
            </Link>
        </nav>

        {(serviceUnavailable || cardLoading) && <div className='absolute top-0 left-0 w-full h-full bg-gray-500 opacity-[0.50]'>

        </div>}

        <div className='relative w-full flex flex-col justify-center items-center space-y-4 pt-[4rem]'>
            
            <div className='relative flex flex-col space-y-2 w-full min-h-[150px] px-3 py-5 bg-Userbglight border border-Userbglight rounded-md sm:w-[500px]'>
                <h3 className='text-[19px] text-[#eaeaea] tracking-wide'>Pending payment</h3>

                <div className='w-full flex items-center justify-between'>
                    <p className='text-[17px] text-[#d0d0d0] tracking-wider'>${paymentData.pendingpaymnet}</p>

                    <button onClick={()=> togglePayment()} className='w-[90px] h-[30px] bg-UserOrageLight border border-UserOrageLight rounded-md'>
                        <p>Pay +</p>
                    </button>
                </div>
            </div>  


            {activeDeposit && <div className="z-50 fixed top-0 left-0 w-full min-h-full flex justify-center items-center text-[#161616]">

                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-600 opacity-[0.50]"></div>
                    </div>
                    
                    
                    <div className="shadow-2xl relative px-6 py-8 w-[95%] h-[600px] bg-white border border-[#f7f7f7]rounded-[15px] sm: max-w-[600px]">
                            
                            {serviceUnavailable && <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center '>
                                    <div className='absolute top-0 left-0 w-full h-full bg-gray-500 opacity-[0.50]'>

                                    </div>

                                    <div className='relative bg-white border border-white rounded-[10px] max-w-[300px] min-h-[50px] flex justify-center items-center px-2 py-2'>                                           
                                            <p className='w-full text-text font-[550] p-4 text-center'>This service is currently unavailable. Please try other payment methods.</p>
                                    </div>
                            </div>}
                            
                            <p className='text-[18px] font-[600] tracking-wide pb-[3rem]'>Recommended payment methods</p>

                            <div className='w-full flex flex-col justify-start items-start space-y-6 p-2'>
                                    <button onClick={()=> updatePaypaLoadingFunc()} className=' w-full min-h-[5rem] flex justify-center items-center border-[0.30px] border-[#d4d4d4] rounded-md hover:border-[#000000]'>
                                            {!paypalLoading ?
                                                <img src={paypalPaymentImage} alt='paypal' />
                                            :
                                                <img src={loadingblack} className='w-[40px] h-[40px]' alt='loading' />
                                            }

                                    </button>

                                    <button onClick={()=> updateCardLoadingFunc()} className='p-1 w-full min-h-[5rem] flex justify-center items-center border-[0.30px] border-[#d4d4d4] rounded-md hover:border-[#000000] '>
                                            {!cardLoading ?
                                                <img src={cardPaymentImage} className='' alt='card' />
                                            :
                                                <img src={loadingblack} className='w-[40px] h-[40px]' alt='loading' />
                                                
                                            }
                                    </button>

                                    <button onClick={()=> updateCryptoLoadingFunc()} className=' w-full min-h-[5rem] flex justify-center items-center border-[0.30px] border-[#d4d4d4] rounded-md hover:border-[#000000]'>
                                            <img src={cryptoPaymentImage} alt='crypto' />
                                    </button>
                            </div>

                            <div className='pt-[3rem] w-full flex justify-start items-center space-x-2'>

                                <button onClick={() =>togglePayment()} className='p-1 flex justify-start items-center space-x-2'>
                                    <ImArrowLeft2 className='font-[550]'/>
                                    <p className='text-[16px] font-[550]'>go back</p>
                                </button>

                            </div>
                    </div>
            </div>}

            <div className='text-black text-[14px] w-full sm:w-[500px]'>Need help? Contact customer <a href='mailto:support@swiftlinecargos.com' className='text-UserOrageLight'>support</a> for assistance </div>

        </div>

    </div>
  )
}

export default PaymentInfo 